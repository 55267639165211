@media only screen and (max-width: 1023px) {
  .course-sidebar {
    min-width: 100% !important;
  }
}
 

 

.course-sidebar {
  background-color: transparent;
  position: relative;
  margin-top: 20px;


  h3 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .ant-card {
    border-radius: 6px;
    margin-bottom: $layout-space * 2;
    position: relative;
    z-index: 0;

    &:not(:first-of-type) {
      &::before {
        content: ' ';
        border-left: 3px solid #6dfe53;
        position: absolute;
        z-index: 1;
        top: -1px;
        left: 47px;
        height: 25px;
      }
    }

    &:not(:last-child) {
      &::after {
        content: ' ';
        border-left: 3px solid #6dfe53;
        position: absolute;
        z-index: 1;
        top: 69px;
        left: 47px;
        height: 86px;
      }
    }

    .ant-card-body {
      align-items: center;
      display: flex;
      .number {
        background-color: transparent;
        border-radius: 50%;
        border: 1px solid $main-text-color;
        display: inline-block;
        line-height: 45px;
        height: 45px;
        padding: 0;
        width: 45px;

        @include md-max {
          margin-right: 9px;
        }

        @include sm-max {
          margin-right: 9px;
        }

        margin-right: $layout-space;

      }
      .title {
        font-size: 13px;
        margin-bottom: 0;
        margin-right: auto;
        max-width: 300px;
        text-align: left;

        @include md-max {
          padding-right: 5px;
          font-size: 12px;
        }

        @include sm-max {
          padding-right: 5px;
          font-size: 12px;
        }

        
      }
    }
  }
}
