.ant-layout-footer {
  background-color: $white;
  border-top: 2px solid $border-layout-color;
  color: $dark-grey;
  height: $footer-height;
  line-height: 1;
  padding: 0;
}
.footer-system {
  align-items: center;
  display: flex;
  height: $footer-height - 2px;
  padding: 15px;
  .logo {
    height: 100%;
    margin-right: $layout-space * 2;
  }
  .logo {
    max-height: 100%;
  }
  .footer-links {
    display: flex;
  }
  .footer-link-item {
    + .footer-link-item {
      &::before {
        content: '|';
        display: inline-block;
        margin: 0 $layout-space;
      }
    }
  }
  .footer-link {
    color: $dark-grey;
    font-size: 12px;
    font-weight: bold;
  }
  .address {
  }
  .footer-text {
    color: $dark-grey;
    font-size: 10px;
    font-weight: bold;
  }
  .social-network-ct {
    align-items: center;
    display: flex;
    &::before {
      content: '|';
      display: inline-block;
      margin: 0 $layout-space;
    }
  }
  .social-network-list {
    align-items: center;
    display: flex;
  }
  .social-network-item {
  }
  .social-network-link {
    color: $footer-social-icon-color;
    margin-left: calc($layout-space / 2);
  }
}
