$xs: '480px';
$sm: '576px';
$md: '768px';
$lg: '991px';
$xl: '1200px';
$xxl: '1600px';

.checkout-footer a .ant-btn {
  padding-top: 0px !important;
}

// Header 
.checkout-header {
  background-color: hsl(233, 54%, 13%);
  padding: 45px 75px;
  height: 100px;
}

.checkout-header-logo {
  min-width: 154px;
  height: 57px;
}

.checkout-menu-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.checkout-header-content {
  width: 100%;
}

.checkout-menu-header-element {
  display: inline-block;
  color: #FFF;
  margin-right: 20px;
}
 
.checkout-menu-header-element:hover {
  color: white;
  opacity: 0.8;
  padding-bottom: 4px;
  border-bottom: 1px solid white;
}

.ant-layout-header .checkout-header-button {
  color: #FFF;
  background-color: #F89C3A;
  border: none;
  padding: 0px 20px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-layout-header .ant-icon  {
  margin-right: 0;
}

.collapsed-header {
  position: fixed;
  display: flex;
  padding: 0px 75px;
  align-items: flex-start;
  background-color: #0F1332;
  width: 100vw;
  height: calc(100% - 90px);
  left: -100%;
  bottom: -100%;
  transition: all 0.7s;
  z-index: 3;
} 

.collapsed-header ul {
  font-size: 20px;
  margin-bottom: 18px;
}

.collapsed-header .checkout-menu-header-element:hover {
  padding-bottom: 0;
  border: none;
} 
.open {
  left: 0;
  bottom: 0;
}

.hamburguer-react {
  width: 48px;
  height: 48px;
}

.no-overflow {
  overflow: hidden;
}

@media(max-width: $lg) {
  .checkout-header {
    padding: 45px 30px;
  };

  .collapsed-header {
    padding: 0px 30px;
  }
}
// Footer


.checkout-footer {
  padding: 75px;
  background-color: #0F1332;
  height: auto;
};

.checkout-footer a.ant-btn {
  padding-top: 0px !important;
} 

.checkout-footer-logo {
  max-width: 132px;
  max-height: 86px;
};

.checkout-footer-data-container {
  line-height: 13px;
  white-space: nowrap;
};

.checkout-footer-data-title {
  color: #FFF !important;
  font-weight: 600 !important;
  font-size: 18px !important;
};

.checkout-footer-data-text {
  color: #FFF;
  font-weight: 400;
  font-size: 16px;
};

.checkout-footer-data-contact {
  color: #F89C3A;
  font-weight: 400;
  font-size: 16px ;
};

.checkout-footer-contact-button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  color: #FFF;
  background-color: #F89C3A;
  border: none;
  font-size: 14px;
  line-height: 15px;
  font-weight: 500;
  max-width: 235px;
};

.checkout-footer-links-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
};

.checkout-footer-divider {
  background: #fff;
  margin: 75px 0;
}
.checkout-footer-links-area {
  display: flex;
  flex-direction: column;
  font-size: 16px;
};

.checkout-footer-links-title {
  color: #FFF;
  font-weight: 600;
  line-height: 15px;
  margin-bottom: 27px;
};

.checkout-footer-links-element {
  color: #595E83 !important;
  font-weight: 500;
  line-height: 15px;
  margin-bottom: 14px;
  white-space: nowrap;
};

.checkout-footer-links-element:hover {
  opacity: 0.8;
}; 

.checkout-footer-social-media-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
};

.checkout-footer-social-media-content {
  display: flex;
  flex-wrap: nowrap;
};


.checkout-footer-social-media-element {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  background-color: #F89C3A;
  color: white;
  border: none;
  margin-right: 8px;
};

@media (min-width: $xl) {
  .checkout-footer-contact-button {
    min-width: 235px;
  }
}

@media(max-width: $xl) and (min-width: $lg) {
  .checkout-footer-data-title {
    font-size: 15px !important;
    line-height: 11px;
  };

  .checkout-footer-data-contact {
    font-size: 13px;
  };
  
  .checkout-footer-data-text {
    font-size: 13px;
  };

  .checkout-footer-contact-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    font-size: 10px !important;
    max-width: 147px !important;
  };  
}

@media(max-width: $lg) {
  .checkout-footer {
    padding: 45px 60px;
  };
  
  .checkout-footer-logo {
    max-width: 90px;
    max-height: 70px;
    order: 1;
  };
  
  .checkout-footer-data-container {
    order: 3;
  };

  .checkout-footer-contact-container {
    flex-wrap: wrap;
    order: 2;
  }
  
  .checkout-footer-contact-button {
    margin-bottom: 50px;
  };

  .checkout-footer-divider {
    margin: 40px 0;
  }

  .checkout-footer-links-container {
    display: flex;
    justify-content: flex-start;
  };
  
  .checkout-footer-links-area {
    display: flex;
    flex-direction: column;
    font-size: 16px;
  };
  
  .checkout-footer-links-title {
    font-size: 14px;
    margin-bottom: 20px;
  };
  
  .checkout-footer-links-element {
    font-size: 14px;
    margin-bottom: 14px;
  };
  
  
  .checkout-footer-social-media-container {
    display: flex;
    flex-wrap: wrap;
  };
  
  .checkout-footer-social-media-content {
    flex-wrap: wrap;
    max-width: 170px;
  };
  
  
  .checkout-footer-social-media-element {
    margin-right: 14px;
    margin-bottom: 12px;
  };

  .toro-academy, .conteudo, .legal {
    margin-right: 40px;
  }
}

@media(max-width: $md) {
  .checkout-footer {
    padding: 45px 30px;
  };
  
  .checkout-footer-links-title {
    font-size: 12px;
    margin-bottom: 20px;
  };
  
  .checkout-footer-links-element {
    font-size: 12px;
    margin-bottom: 14px;
  };

}

@media(max-width: $sm) {
  .checkout-footer-data-title {
    font-size: 15px !important;
    line-height: 11px;
  };


  .checkout-footer-data-contact {
    font-size: 13px;
  };
  
  .checkout-footer-data-text {
    font-size: 13px;
  };

  .toro-academy {
    order: 1;
  }

  .toro-academy, .conteudo, .legal {
    margin-right: 30px;
  }

  .conteudo {
    order: 3;
    margin-right: 67px;
  }

  .legal {
    order: 2;
  }

  .checkout-footer-social-media-container {
    order: 4;
  };

  
}

@media(max-width: $xs) {
  .checkout-footer-logo {
    max-width: 65px;
    max-height: 65px;
    order: 1;
  };

  .checkout-footer-data-title {
    font-size: 11px !important;
    white-space: normal;
    line-height: 11px;
  };
  
  .checkout-footer-data-text {
    color: #FFF;
    font-weight: 400;
    white-space: normal;
    font-size: 11px;
  };
  
  
  .checkout-footer-data-contact {
    font-size: 11px;
    white-space: normal;
  };

  .checkout-footer-contact-button {
    margin-bottom: 40px;
    font-size: 10px !important;
    max-width: 147px !important;
  };
}