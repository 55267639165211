.header-simulated {
  flex-direction: column;
  align-items: flex-start !important;
  background-color: #FFFFFF;
}
.title-simulated {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  color: #000000;
  margin-bottom: 20px;
  width: 100%;
  border-bottom: 0.5px solid #eeeeee;
  padding-bottom: 5px;
  margin-top: -30px;
}
.print-button{
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  color: #5768FF;

  border-radius: 5px;
  border: 1px solid #5768FF;

  background: none;

  transition: filter 1s;

  cursor: pointer;

  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.print-button:hover {
  color: #5768FF;
  filter: brightness(0.8);
}

.print-button:disabled {
  opacity: 0.4;
  cursor: wait;
}

.simulate-area{
  display: flex;
  flex-direction: row;
  margin: 20px auto;
}

@media screen and (max-width: 620px) {
  .print-button{
    width: 100%;
  }
}
