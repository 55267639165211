.ant-btn {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: bold;
  span {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
  }
}
.ant-btn-link {
  span {
    color: #707070;
    font-size: 12px;
    font-weight: 400;
    &:hover {
      text-decoration: underline;
    }
  }
}
.ant-btn-primary {
  background-color: $button-action-color;
  border: none;
  border-radius: 6px;
  // height: 47px;
  // line-height: 47px;
  padding: 0 $layout-space;
  &.current{
    background-color: #40a9ff;
  }
}
