.page {
  &.course {
    .course-content {
      max-width: 1440px;
      margin-top: 20px;
      padding: 0;
      @media(max-width: 720px) {
        padding-right: 0px;
      }

      .content-title {
        color: $dark-grey;
        font-size: 24px;
        font-weight: 500;
      }

      h3 {
        font-size: 20px;
        margin-bottom: 20px;
      }

      .course-section-content {
        align-items: left;
        // background-color: $white-color;
        border-radius: 4px;
        display: flex;
        margin-bottom: $layout-space;
        padding: $layout-space $layout-space * 3;

        background-color: $white-color;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        // background-image: url('../../img/pdf-bg.png');
        background-repeat: no-repeat;
        padding: 88px 70px 135px 88px;
        background-position: right 100px;

        @include sm-max {
          padding: 80px 20px;
        }

        h2 {
          font-size: 65px;

          @include md-max {
            font-size: 45px;
          }

          @include sm-max {
            font-size: 1rem;
          }

          font-weight: bolder;
          text-transform: uppercase;
          line-height: 65px;
          margin: 0 0 40px 0;

          span {
            font-weight: 400;
            display: block;
          }
        }

        p {
          font-size: 18px;
          font-weight: 500;
        }

        .pdf-button {
          margin-top: 55px;
        }
      }

      .pdf-section {
        align-items: center;
        // background-color: $white-color;
        border-radius: 4px;
        display: flex;
        margin-bottom: $layout-space;
        padding: $layout-space $layout-space * 3;
      }
      .pdf-button{
        margin-top: 20px;
      }

      .no-pdf-section-content {
        background-image: none !important;
      }

      .pdf-section-content {
        align-items: flex-start;
        background-color: $white-color;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        background-image: url('../../img/pdf-bg.png');
        background-repeat: no-repeat;
        padding: 88px 70px 135px 88px;
        background-position: right 100px;

        h2 {
          font-size: 65px;

          @include md-max {
            font-size: 45px;
          }

          @include sm-max {
            font-size: 45px;
          }

          font-weight: bolder;
          text-transform: uppercase;
          line-height: 65px;
          margin: 0 0 40px 0;

          span {
            font-weight: 400;
            display: block;
          }
        }

        p {
          font-size: 18px;
          font-weight: 500;
        }

        .pdf-button {
          margin-top: 55px;
        }
      }

      .pdf-icon {
        font-size: 132px;
      }
      .pdf-text-ct {
        flex-grow: 1;
        font-size: 18px;
        line-height: 1;
        padding: 0 $layout-space;
      }
      .pdf-title {
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
      }
      .pdf-text {
        display: block;
      }
      .pdf-button {

      }
    }
    .video-class {
      iframe {
        width: 100%;
      }
    }



    .mobile-steplist-title {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: $layout-space * 2;
    }
    .mobile-steplist {
    }
    .mobile-steplist-item {
      background-color: white;
      border-radius: 5px;
      margin-bottom: $layout-space * 2;
      padding: 0 $layout-space $layout-space;
      position: relative;
      &.-finished {
        background-color: #000533;
        .mobile-steplist-icon {
          background-color: #000533;
          border: 0;
          color: #81f871;
          font-size: 36px;
        }
        .mobile-steplist-text {
          color: white;
        }
      }
      &:after {
        background-color: #81f871;
        content: '';
        display: block;
        height: 40px;
        position: absolute;
        width: 2px;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
    }
    .mobile-steplist-icon {
      background-color: white;
      color: #000533;
      display: inline-block;
      border: 2px solid #000533;
      border-radius: 50%;
      font-size: 16px;
      font-weight: bold;
      height: 36px;
      line-height: 36px;
      transform: translateY(-50%);
      width: 36px;
    }
    .mobile-steplist-text {
      color: #000533;
      margin-bottom: $layout-space;
    }
    @include xl-max {
      .course-content {
        text-align: center;
      }
    }
  }
}
