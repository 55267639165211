.new-checkout {
    .box-cupom-free {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 15px;
        height: 300px;

        .new-checkout-card {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: #F6F6F6;
            position: relative;

            svg {
                font-size: 60px;
                color: #2EC76B;
            }
    
            
            h1 {
                color: #2EC76B;
                margin-top: 2.5%;
                font-size: 22px;
                font-weight: 700;
            }
    
            h2 {
                color: #2EC76B;
                font-size: 16px;
                font-weight: bold;
                text-align: center;
                margin-top: 2.4%;
            }
    
            a {
                margin-top: 4%;
            }

            .payment-free-remove {
                position: absolute;
                top: 10px;
                right: 10px;

                svg {
                    font-size: 20px;
                    color: #D81E5B;
                    font-weight: 700;
                    cursor: pointer;
                }
            }
        }
    }
}
