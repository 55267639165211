.ant-modal-body {
  padding: 0px !important;
}

.lectureResultModal {

  .ant-modal-body {
    padding: 0 0 40px !important;
  }
  
  .lectureResultIcon {
    position: absolute;
    height: 140px;
    width: 140px;
    background-color: white;
    border-radius: 50%;
    top: -70px;
    left: 80px;
    -webkit-box-shadow: 0px 4px 7px 1px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 4px 7px 1px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 4px 7px 1px rgba(0, 0, 0, 0.16);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .resultContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;
        width: 100%;
        height: 74px;
        background: linear-gradient(100.59deg, #D81E5B -8.92%, #000A66 120.67%);
        color: #fff;
        font-size: 20px;
        font-weight: 700;
          line-height: 34px;
          text-align: center;
        }
        
        .icon {
          margin-bottom: 30px;
          width: 90px;
          height: 90px;
        }
    p {
      text-align: left;
        margin: 0 75px 30px;
        font-weight: 500;
        font-size: 22px;
        line-height: 34px;
      span {
        color: green;
        font-weight: bold;
      }
    }

    button {
      display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8px;
      
        width: 422px;
        max-width: 80%;
        height: 40px;
        margin: 0 auto;
        font-size: 16px;
        font-weight: 400;
        color: #fff;
      
        background: #5768FF;
        border: 1px solid #5768FF;
      
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
        border-radius: 5px;
    }
  }
}
