.ant-form-item-label {
  label {
    color: #222222;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    &.ant-form-item-required {
      &:not(.ant-form-item-required-mark-optional) {
        &:before {
          display: none;
        }
      }
    }
  }
}
.ant-input,
.ant-input-password,
.ant-picker,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #999999;
  border-radius: 6px;
  line-height: 40px;
}

.ant-picker {
  width: 100%;
}

.ant-picker-input {
  > input {
    line-height: inherit;
  }
}

.form-subtitle {
  color: #222222;
  font-size: 16px;
  font-weight: bold;
}
.form-text {
  color: #222222;
  font-size: 16px;
  .link {
    color: #1197fa;
    &:hover {
      text-decoration: underline;
    }
  }
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  &,
  .ant-select-selection-item,
  .ant-select-selection-search-input {
    height: 50px;
    line-height: 50px;
  }
}
