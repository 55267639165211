@media only screen and (min-width: 1570px) {
  .page {
    &.login {
      background-image: url('../../img/bg-2.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100% !important;
      margin-left: -3vw;
    }
  }
}
.bg-lienar {
  background-color: #000131 !important;
}

@media only screen and (min-width: 992px) {
  .page {
    &.login {
      background-image: url('../../img/bg-2.png');
      background: no-repeat center;
      background-size: cover; 
    }
  }
}


@media only screen and (min-width: 1600px) {
  .page {
    &.login {
      background-image: url('../../img/bg-2.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover !important;
      margin-left: -3vw;
    }
  }
}

.page {
  &.login {
    background-image: url('../../img/bg-2.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover !important;
    margin-left: -3vw;
  }
}



.login-text-container {
  background-repeat: no-repeat;
  background-position: 90% bottom;
  background-size: auto 90%;
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 4vw;
  @include md {
    min-height: 100vh;
  }
}
.login-form-container {
  background-color: #004b8af2;
  margin: 0 0 0 auto;
  min-width: 460px;
  // max-width: 573px;
  max-width: 34vw; //26
  min-height: 100vh;
  // height: 23vw;
  padding: $layout-space * 2;
  .logo {
    margin-bottom: 45px;
    width: 10vw;

  }
  @media only screen and (max-width: 768px) {
    .logo {
      margin-bottom: 45px;
      width: 30vw;
  
    }
  }
  .login-form-bg {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(#000000, 0.16);
    padding: $layout-space * 2;
    width: 100%;
  }
  .ant-btn-link {
    span {
      color: #1197fa;
      font-size: 16px;
    }
  }
  @include md-max {
    min-width: 0px;
    max-width: 100%;
    .login-form-bg {
      margin: 0 auto;
      max-width: 400px;
      width: 100%;
    }
  }
}
.sidebar-bottom {
  margin: $layout-space * 2 0;
}

.p-0 {
  padding: 0;
}
