.user-menu {
  align-items: center;
  border-bottom: 1px solid $border-layout-color;
  display: flex;
  justify-content: space-between;
  padding: $layout-space;
  .profile-img {
    border: 2px solid $dark-grey;
    border-radius: 50%;
    margin-right: $layout-space;
    width: 23%;
  }
  .notifications {
    position: relative;
    margin-right: $layout-space;
    .anticon {
      color: $dark-grey;
      font-size: 25px;
    }
  }
  .notification-count {
    background-color: $notification-count-bg;
    border-radius: 50%;
    color: #000;
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    height: 20px;
    line-height: 20px;
    position: absolute;
    text-align: center;
    left: 50%;
    transform: translateY(-50%);
    top: 50%;
    width: 20px;
  }
  .ant-dropdown-link {
    align-items: center;
    color: $dark-grey;
    cursor: pointer;
    display: flex;
    flex-grow: 1;
    font-size: 12px;
  }
}
