.container-exist-cpf {
    border-radius: 10px;
    .ant-modal-content {
        border-radius: 10px;
        height: 328px;
        width: 604px;
        @media only screen and (max-width: 768px) {
            width: 100%;
        }
        .container-exist-cpf-body{
            border-radius: 10px;
            padding-top: 10%;
            display: flex;
            flex-direction: column;
            flex: 1;
            justify-content: space-around;
            align-items: center;
            h1{
                text-align: center;
                font-size: 30px;
                font-weight: 600;
                color: #000A66;
            }

            .container-exist-cpf-buttons {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 25px;

                button{
                    text-align: center;
                    border-radius: 4px;
                    width: 351px;
                    height: 45px;
                    margin-top: 14px;
                    font-size: 14px;
                    font-weight: 600;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    @media only screen and (max-width: 375px) {
                        width: 100%;
                    }

                    transition: filter 0.2;
                    &:hover {
                        filter:brightness(0.9);
                    }
                }

                .button-warning{
                    color: white;
                    background-color: #F89C3A;
                    border: none;
                }

                .button-primary{
                    background-color: #FFF;
                    border: 2px solid #000A66;
                    color: #000A66;
                }
            }
        }
    }    
}