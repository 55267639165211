.new-checkout {
    .container-card-course-error {
        background: #AAA;
    }
    
    .total-free {
        color: #2EC76B;
    }

    .main-img-card {
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
}
