.box-podcast-listen-button {
    display: flex;
    justify-content: flex-end;
    @media(max-width: 600px) {
        justify-content: center;
    }
    margin-bottom: 10px;
    margin-top: -20px;
}

.box-see-podcast {
    background-color: white;
    padding: 30px;
    @media(max-width: 600px) {
        padding: 10px;
    }

    border-radius: 5px;
    .box-player {
        margin-top: 40px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
        .player {
            margin-top: 50px;
            padding-top: 20px;
            border-top: 2px solid #EEEEEE;
        }
        min-height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .box-footer {
        margin-top: 30px;
        @media(max-width: 600px) {
            display: none;
        }
    }
}