.lessons-list {
  cursor: pointer;
  margin-top: -$layout-space;
  overflow: auto;
  padding: $layout-space;
  white-space: nowrap;

  display: flex;
  align-items: center;

  @media(max-width: 728px) {
    margin-top: 10px;
  }

}

::-webkit-scrollbar {
  -webkit-appearance: none !important;
  appearance: none !important;
  background-color: #d0d0d0;
  border: none;
}

::-moz-scrollbar-button, ::-webkit-scrollbar-button {
  width: 0px;
  border: none;
}

::-webkit-scrollbar-track {
  background-color: #eeeeee;
  border: none;
}

::-webkit-scrollbar-thumb {
  background-color: #d0d0d0;
  border-radius: 10px;
}



.lessons-item:hover {
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
}

.lessons-item {
  background-color: $lessons-list-bg-color;
  color: $white-color;
  display: inline-block;
  padding: calc($layout-space / 2) $layout-space;
  position: relative;
  min-width: 270px;
  border-radius: 6px;
  transition: box-shadow 0.3s ease-in-out;
  @include xl-max {
    min-width: 264px;
    max-width: 60vw;
    .list-title {
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .ant-row {
    justify-content: space-between;
  }
  .list-number {
    display: block;
    font-size: 20px;
  }
  .list-title {
    color: $list-title-color;
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
    height: 40px;
    p {
      white-space: pre-wrap;
      width: 100%;
    }
  }
  .list-percent {
    border-radius: 50%;
    color: $main-text-color;
    display: inline-block;
    font-size: 11px;
    height: 40px;
    line-height: 40px;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(-50%);
    text-align: center;
    width: 40px;
    font-size: 18px;
  }
  .right {
    padding-left: $layout-space;
    align-items: flex-end;
    display: flex;
  }
  .list-days {
    display: flex;
    font-size: 11px;
    color: #999999;
  }
  .list-day {
    text-align: right;
  }

  .list-day {
    + .list-day {
      .list-dow,
      .list-date {
        &:before {
          content: '-';
          display: inline-block;
          margin: 0 calc($layout-space / 2);
        }
      }
    }
  }

  .list-dow {
    font-weight: bold;
  }

  .list-dow,
  .list-date {
    display: block;
  }

  &.unlocked {
    .list-percent {
      background-color: $lessons-list-bg-color;
      border: 1px solid $lessons-list-border-color;
      color: black;
    }
  }
  &.todo {
    .list-percent {
      background-color: $grey-cccccc;
    }
  }
  &.locked {
    .list-percent {
      background-color: #e51616;
      color: $white-color;
    }
  }
  &.average {
    .list-percent {
      background-color: #fdcd00;
    }
  }
  &.approved {
    .list-percent {
      background-color: #81f871;
    }
  }

  &.current {
    background-color: $white-color;
    border-color: $white-color;
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
    .list-number,
    .list-days {
      color: $main-text-color;
    }
  }
  + .lessons-item {
    margin-left: $layout-space;
  }
}
