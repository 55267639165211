#btn-full-result-warning {
    background-color: #F89C3A;
    color: white;
    border-radius: 5px;
    border:none;
    width: 195px;

    transition: filter 0.2s;
    &:hover {
        background-color: #F89C3A;
        color: white;
        filter: brightness(0.8);
    }

    &:enabled {
        background-color: #F89C3A;
        color: white;
    }

    margin-bottom: 10px;
}

.button-prev-question{
    background: #dc1e5d !important;
    color: #ffffff !important;
}

.button-next-question-success{
    background: #5768ff !important;
    color: #ffffff !important;
}