.ant-layout-sider {
  background-color: $sidebar-bg-color;
  color: $dark-grey;
  .sidebar-top {
    align-items: center;
    border-bottom: 1px solid $border-layout-color;
    display: flex;
    justify-content: space-between;
    padding: 24px;
  }
  .sidebar-icon-open-close {
    display: flex;
    justify-content: flex-end;
    padding-right: 2vw;
  }
  .logo {
    display: inline-block;
    height: 100%;
    max-height: 100%;
  }
    
  @include sm-max {
    left: 0;
    position: absolute;
    top: 0;
    z-index: 1;
    .mobile-toggle-sidebar {
      cursor: pointer;
    }
    .sidebar-content {
      background-color: $sidebar-bg-color;
      height: 100vh;
      left: 0;
      position: fixed;
      top: 0;
      transform: translateX(-100%);
      transition: transform 400ms ease-in-out;
      width: calc(100% - 50px);
      z-index: 2;
      &.open {
        transform: translateX(0%);
      }
    }
    .logo {
      height: 50px;
      display: flex;
      align-items: center;
  
      img {
        height: auto;
      }
    }
  }
  @include md {
    min-height: calc(100vh - #{$footer-height});
  }
}
