.modal-lecture-video {
  .ant-modal-close-x {
    width: fit-content !important;
    height: fit-content !important;

    line-height: 0 !important;
  }
}

.button-close {
  width: 40px;
  height: 40px;

  border: none;

  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;

  line-height: 0;
  font-weight: bold;

  cursor: pointer;
}
