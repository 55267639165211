$color-shape: #C4C4C4;
$color-white: #FFFFFF;
$color-black: #000000;
$color-gray-1: #999999;
$color-gray-2: #eeeeee80;
$color-primary: #000A66;

$color-subtitle: #999999;
$color-title: #000000;

$font-size-title: 3em;
$font-size-subtitle: 2.6em;
$font-size-default: 1.6em;
