.sidebar-menu {
  background-color: $sidebar-bg-color;
  color: $dark-grey;
  .ant-menu-item {
    a {
      color: inherit;
      transition: none;
    }
  }
}
