.box-contract {
    .input-contract {

        color: #000A66;
        font-weight: 500;

        .ant-checkbox-inner {
            border: 1px solid #000A66;
            border-radius: 50%;
            clip: rect(0 0 0 0);
        }
        
        .btn-accept-the-contract {
            color: #000A66;
            line-height: 17.07px;
            border-bottom: 1px solid #000A66;
        }
    }
}

.from-termos {
    margin-bottom: 0 !important;
}

@media only screen and (min-width: 320px) {
    .payment {
        margin-bottom: -10vw;
    }
}

@media only screen and (min-width: 720px) {
    .payment {
        margin-bottom: -7vw;
    }
}

.payment {
    margin-bottom: -1vw;
}
