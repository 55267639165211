.home {
  .ant-layout-content {
  }
  .ant-card {
    margin-bottom: $layout-space;
    text-align: center;
    width: 100%;
  }
  .ant-card-body {
    display: flex;
    flex-direction: column;
    min-height: 140px;
  }
  .card-title {
    flex-grow: 1;
  }
  .card-button {
    background-color: $button-action-color;
    border: 0;
    color: $white-color;
    display: block;
    font-size: 12px;
    margin-top: $layout-space;
    margin-bottom: 0;
    text-transform: uppercase;
    width: 100%;
  }
}
