.lectureContainerVideo {
  .anticon {
    color: #fff !important;
  }
}
.lectureContainer {
  z-index: 999999999;
  .ant-modal-body {
    padding: 0;

    @media only screen and (max-width: 650px) {
        width: 100%;
      }
    .lecture-icon {
      position: absolute;
      height: 140px;
      width: 140px;
      background-color: white;
      border-radius: 50%;
      top: 0px;
      left: 50%;
      box-shadow: 0px 4px 7px 1px rgba(0, 0, 0, 0.16);
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translate(-50%, -50%);
      img {
        width: 48.5%;
      }
      @include md-max {
        height: 70px;
        width: 70px;
      }
    }

    header {
      padding: 40px 36px;
      background-color: #dddddd;

      h1 {
        color: #1a1a1a;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 30px;
      }

      p {
        color: #1a1a1a;
      }
    }

    .questionAnswersContainer {
      display: flex;
        flex-direction: column;
          align-content: space-between;
        padding: 5px 60px 40px;
          max-height: calc(100vh - 335px);
          overflow-y: auto;
          overflow-x: hidden;
          background: #fff;

          @media only screen and (max-width: 1000px) {
              max-height: calc(100vh - 270px);
            }
        @media only screen and (max-width: 650px) {
            padding: 30px 30px 0;
            max-height: none;
p img {
    display: block;
  }
        }
&.review {
  padding-bottom: 75px;
  @media only screen and (max-width: 1000px) {
      max-height: calc(100vh - 335px);
      padding-bottom: 20px;
    }
  
    @media only screen and (max-width: 650px) {
      padding: 30px 30px 75px;
      max-height: none;
      min-height: calc(100vh - 325px);
    }
}
.input-box {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  &.zoom-active {
      height: calc(100vh - 50vh);
    }
  
    @media only screen and (max-width: 800px) {
      margin-left: -60px;
      min-width: 100vw;
    }
  @media only screen and (max-width: 650px) {
    margin-left: -30px;
    min-width: calc(100vw - 15px);
  }
}
ul {
        
        li {
          margin-bottom: 30px;
        }
      }
    }
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: #D6D6D6;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5768FF;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5768FF;
}
}

.ant-modal-close {
  color: #fff;
  height: 80px;
  cursor: pointer;
    top: 75px;
      right: 50px;

  @media only screen and (max-width: 1000px) {
      top: 0;
    right: auto;
    left: 0;
  }
}
  .ant-modal-footer {
    border: none;
    background-color: #fff;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.2);

    button,
    .ant-select,
    span {
      border-radius: 5px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;

      .ant-select-selector {
        border-radius: 5px;
      }
    }

    .btnFinishLecture {
      background-color: #12c735;
      color: white;
    }
  }
  .lecture-modal-footer {
    display: flex;
      justify-content: space-between;
    
    .ant-select-single {
      margin: 0 10px;
      @include md-max {
        margin: 0;
      }
    }
    .commented-question {
      button {
        border: 1px solid #5768FF;
        color: #5768FF;
      }

      @media only screen and (max-width: 650px) {
        position: absolute;
        top: -70px;
        left: 0;
        right: 0;
        background: #fff;
        display: flex;
        justify-content: center;
        padding: 20px 0;
      }
    }
    
    @media only screen and (max-width: 800px) {
      .text-desktop {
        display: none;
        +.anticon {
          margin: 0;
        }
      }
    }
  }
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}