.new-checkout {

    .checkout-validation-error {
        font-size: 13px;
    }

    .checkout-validation-error-mobile {
        font-size: 11.5px;
    }

    .birthday-date-label {
        padding-top: 20px;
    }

    .name-label {
        padding-top: 20px;
    }
}