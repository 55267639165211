.ant-modal-body {
  // margin: -2%!important;
  padding: 0!important;
}
.lectureReviewContainer {
  .ant-modal-body {
    padding: 0;
    header {
      display: flex;
      justify-content: space-between;
      padding: 10px;

      div {
        display: flex;
        align-items: center;
      }

      .titleWrongQuestions {
        span {
          color: #02c213;
          margin: 0 5px;
          font-weight: bold;
        }
      }
      .WrongQuestionsFilter {
        @include md {
          justify-content: flex-end;
        }
      }
    }

    .questionDescription {
      padding: 40px 36px 60px 36px;
      background-color: #d5d5d5;

      h1 {
        color: #1a1a1a;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 30px;
      }

      p {
        color: #1a1a1a;
      }
    }

    .questionVideoContainer {
      margin-bottom: 27px;
      position: relative;
      display: flex;
      justify-content: center;

      button {
        background-color: rgb(0, 0, 85);
        color: white;
        position: absolute;
        top: -30px;
        font-weight: bold;
        border-radius: 5px;
        height: 65px;
        border: 12px solid white;
        -webkit-box-shadow: 0px -1px 24px -8px rgba(0, 0, 0, 0.44);
        -moz-box-shadow: 0px -1px 24px -8px rgba(0, 0, 0, 0.44);
        box-shadow: 0px -1px 24px -8px rgba(0, 0, 0, 0.44);
        display: flex;
        align-items: center;
      }
.questionTextButton {
  margin-left: -200px;

  &+button {
    margin-left: auto;
    margin-right: -250px;
  }
}
    }

    .questionAnswersContainer {
      margin-bottom: 30px;
      padding-bottom: 30px;
      border-bottom: 2px dashed black;

      ul {
        display: flex;
        flex-flow: column wrap;

        li {
          flex-basis: 50%;
          padding: 15px;
          display: flex;
          align-items: center;
          list-style: inside upper-latin;

          &::first-letter {
            text-transform: capitalize !important;
          }
        }
        .correta {
          background-color: #169600;
          color: white;
        }

        .selected {
          background-color: #eaeaea;
        }
      }
    }
  }
}
.modal-body {
  max-height: calc(100vh - 195px);
  overflow-y: scroll;
}