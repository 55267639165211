
.checkout-footer {
    .checkout-footer-contact-button {
        span {
            display: flex;
            margin-top: 5px;
            flex-direction: column;
            justify-content: center;
            height: 100%;
        }
    }
}