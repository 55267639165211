.stepProgressBar {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 10px;
    flex: 1;
    .step {
        display: flex;
        flex: 1;

        &:last-child {
            margin-right: 15px;
        }
    }
}

@media only screen and (min-width: 320px) {
    .stepProgressBar {
        display: flex;
        justify-content: center;
    } 
}